
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from 'react';
import PropTypes from 'prop-types';
import Image from '../../../../../components/Image';
import s from './CollectionDiscoveryItem.module.scss';
import { heroImageSet } from '../../../../../utils/optimizeImage';
const propTypes = {
    isLarge: PropTypes.bool,
    baseURL: PropTypes.string,
    displayTitle: PropTypes.string,
    children: PropTypes.node.isRequired
};
const CollectionDiscoveryItem = ({ isLarge = false, baseURL = '', displayTitle = '', children, }) => (<article className={isLarge ? s.background : s.secondaryItem} data-testid="item-background">
    <Image imageSet={heroImageSet(baseURL)} alt={displayTitle} cover className={s.image} lazy/>
    {children}
  </article>);
CollectionDiscoveryItem.propTypes = propTypes;
export default CollectionDiscoveryItem;

    async function __Next_Translate__getStaticProps__195ca0d3849__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'src//Collection/components/MoreCollections/CollectionDiscoveryItem/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__195ca0d3849__ as getStaticProps }
  