
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from 'react';
import PropTypes from 'prop-types';
import logger from '@utils/logger';
import Collection from '../../src/pages/Collection';
import { fetchContent, withNavigation, pruneCollection } from '../../src/utils/contentfulHelpers';
const revalidate = Number(process.env.REVALIDATION_TIMEOUT_SHORT) || 60 * 5;
const propTypes = {
    collection: PropTypes.shape({
        theme: PropTypes.string,
        collectionDiscoveryEyebrow: PropTypes.string,
        collectionSymbol: PropTypes.shape({}),
        contentTypeId: PropTypes.string,
        contentfulId: PropTypes.string,
        displayTitle: PropTypes.string,
        displayTitleEyebrow: PropTypes.string,
        entryTitle: PropTypes.string,
        externalId: PropTypes.string,
        mainImage: PropTypes.shape({}),
        referencedContent: PropTypes.array,
        referencedContentEyebrow: PropTypes.string,
        referencedContentStatic: PropTypes.array,
        referencedContentStaticEyebrow: PropTypes.string,
        searchDescription: PropTypes.string,
        searchTitle: PropTypes.string,
        shortDescription: PropTypes.string,
        shortDescriptionEyebrow: PropTypes.string,
        spotlightCTA: PropTypes.string,
        spotlightEyebrow: PropTypes.string,
        spotlightURL: PropTypes.string
    }),
    collectionDiscovery: PropTypes.array
};
/* istanbul ignore next */
export const CollectionPage = ({ collection = null, collectionDiscovery = [] }) => {
    return <Collection collection={collection} collectionDiscovery={collectionDiscovery}/>;
};
async function getStaticProps(context) {
    const { externalId } = context.params;
    const params = {
        contentTypes: ['collection'],
        externalId
    };
    const [collections, errors] = await fetchContent(params);
    const location = `Page: /collections/${externalId}`;
    logger(location, collections, errors);
    if (errors || collections.length <= 0) {
        return { notFound: true, revalidate };
    }
    const discoveryList = [];
    const collection = pruneCollection(collections[0].body);
    if (collection.collectionDiscoveryList) {
        // Remove duplicate entries
        collection.collectionDiscoveryList = [...new Set(collection.collectionDiscoveryList)];
        const collectionPromises = collection.collectionDiscoveryList.map(async (collectionId) => fetchContent({
            contentTypes: ['collection'],
            externalIds: collectionId
        }));
        const collectionResponses = await Promise.all(collectionPromises);
        logger(location, collectionResponses);
        collectionResponses.forEach((response) => {
            const [[collectionObj], error] = response;
            if (!error) {
                discoveryList.push(pruneCollection(collectionObj.body));
            }
        });
    }
    const props = await withNavigation({
        collection,
        collectionDiscovery: discoveryList
    });
    return {
        props,
        revalidate
    };
}
export async function getStaticPaths() {
    return {
        fallback: 'blocking',
        paths: []
    };
}
CollectionPage.propTypes = propTypes;
export default CollectionPage;

    async function __Next_Translate__getStaticProps__195ca0c932a__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/collections/[externalId]',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__195ca0c932a__ as getStaticProps }
  